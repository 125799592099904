import { css } from 'styled-components';

export const breakpoints = {
  xs: 359,
  ms: 374, // 23.4375em
  twtr: 570,
  sm: 639, // 40em
  md: 767, // 48em
  lg: 1023, // 64em
  xl: 1139,
  xxl: 1440,
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  const accumulator = acc;
  const emSize = (breakpoints[label] + 1) / 16;
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});

export const color = {
    WHITE: '#FFFFFF',
    CREME: '#F7FFF7',
    LIGHTEST_GRAY: '#F0F5F6',
    LIGHTER_GRAY: '#DDE3E4',
    LIGHT_GRAY: '#EAEFF0',
    GRAY: '#ABC0C3',
    STEEL: '#588188',
    DARK: '#1A535C',
    BLACK: '#030809',
    RED: '#FF6B6B',
    LIGHT_YELLOW: '#FFE66D',
    YELLOW: '#FFC53A',
    LIGHT_ORANGE: '#FFA66F',
    ORANGE: '#FF934F',
    LIGHT_PINK: '#ED7189',
    PINK: '#EA526F',
    LIGHT_PURPLE: '#B698FF',
    PURPLE: '#A682FF',
    LIGHT_BLUE: '#61A5D5',
    BLUE: '#3E92CC',
    LIGHT_GREEN: '#3CAEB4',
    GREEN: '#119DA4',
    LIGHT_TEAL: '#4ECDC4',
    TEAL: '#07BEB8',
    TWITTER: '#1FA1F1',
    TWIT_BORDER: '#CCD6DD',
};

export const typography = css`
  h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.5px;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 21px;
    font-weight: 700;
    line-height: 31.5px;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 31.5px;
    margin-top: 24px;
    margin-bottom: 4px;
  }

  p, i, a, ul, ol {
    font-size: 18px;
    line-height: 1.58;
    margin-bottom: 18px;
  }

  ul {
    list-style: disc;
    margin-left: 32px;
  }

  ol {
    list-style: decimal;
    margin-left: 32px;
  }

  a {
    text-decoration: none;
    color: ${color.TEAL};
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    font-size: 30px;
    font-style: italic;
    letter-spacing: -0.36px;
    line-height: 44.4px;
    overflow-wrap: break-word;
    margin: 55px 0 33px 0;
    /* text-align: center; */
    color: rgba(0, 0, 0, 0.68);
    padding: 0 0 0 50px;
  }

  q {
    font-weight: bold;
    display: block;
    margin-bottom: -1rem;
    &::before {
      content: '';
    }

    &::after {
      content: '';
    }
  }

  table {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
    word-wrap: normal;
    table-layout: fixed;
    display: table;
    overflow: hidden;
    overflow-x: auto;
    border-collapse: collapse;
    border-spacing: 0;
  }

  tbody tr {
    border-bottom: 1px solid ${color.GRAY};
    &:last-child {
      border-bottom: none;
    }
  }

  thead tr {
    border-bottom: 2px solid ${color.BLACK};
  }

  th, td, th a, td a {
    font-size: 1rem;
    line-height: 1.2em;
  }

  th {
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.5rem 1em 0.5rem 0;
    vertical-align: bottom;
  }

  td {
    line-height: 1.5em;
    padding: 0.5rem 1rem 0.5rem 0;
    vertical-align: top;
  }
`