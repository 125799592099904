import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { color, media } from "../../theme"
import SectionLayout from "../SectionLayout"
import ContentContainer from "../ContentContainer"
import Logo from "../Logo"
// import StoreButtons from "../StoreButtons"
import SocialButtons from "../SocialButtons"

const LogoWrapper = styled.div`
  width: 200px;
  height: 70.5px;
  margin: 0 auto 2rem;

  ${media.sm`
      width: 215px;
      height: 75.7875px;
  `}
`;

const SocialButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
`

const Copyright = styled.span``

const Bottom = styled.div`
  color: ${color.CREME};
  font-size: 0.85rem;
  text-align: center;
  opacity: 0.75;
  margin-bottom: 3rem;
  ${media.lg`
    margin-bottom: 4rem;
  `}
`

const Privacy = styled(Link)`
  color: ${color.CREME};
  margin-left: 1rem;
`

// const StoreButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   margin-bottom: 2rem;
// `

const Footer = ({ bgColor, ...props }) => {
  return (
    <SectionLayout bgColor={bgColor} {...props}>
      <ContentContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <SocialButtonContainer>
          <SocialButtons />
        </SocialButtonContainer>
        {/* <StoreButtonContainer>
          <StoreButtons />
        </StoreButtonContainer> */}
        <Bottom>
          <Copyright>
            © 2021 Blinking Cursor
          </Copyright>
          <Privacy to="/privacy-policy">Privacy Policy</Privacy>
        </Bottom>
      </ContentContainer>
    </SectionLayout>
  )
}

export default Footer
