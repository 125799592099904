import React from "react"
import styled from "styled-components"

import { color, media } from "../../theme"

const Wrapper = styled.div`
  padding: 2rem 1rem 0; /* essentially body padding */
  background-color: ${props => color[props.bgColor]};
  /* -10% horizontal margin below would cause horizontal scroll */
  overflow: hidden;
  ${props => props.last && `
    margin-bottom: 2rem;
  `}
  ${media.md`
      padding-top: 2rem;
  `}

  ${media.lg`
      padding-top: 4rem;
  `}
`

const Layout = ({ bgColor, children, last, ...props  }) => {
  return (
    <Wrapper bgColor={bgColor} last={last} {...props}>
      {children}
    </Wrapper>
  )
}

export default Layout
