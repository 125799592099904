import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 62rem; /* constrain page content to 992px */
    padding: 1rem 0;
`

const Layout = ({ children }) => (
  <Wrapper>
      {children}
  </Wrapper>
)

export default Layout
