import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SiteName = styled.h1`
  text-indent: -9999px;
`

const Logo = ({ bgColor, children, ...props  }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1384) {
            ...GatsbyImageSharpFluid
          },
        }
      },
    },
  `)

  return (
    <React.Fragment>
      <Img
        fluid={data.logo.childImageSharp.fluid}
        title="Goody Points"
        alt="Goody Points"
      />
      <SiteName>Goody Points</SiteName>
    </React.Fragment>
  )
}

export default Logo
