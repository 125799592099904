import React from "react"
import styled, { createGlobalStyle, StyleSheetManager } from "styled-components"
import CookieConsent from "react-cookie-consent"
import { color, media } from "../../theme"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .cookie-consent {
    padding: 1.5rem;
    background-color: ${color.DARK};
    align-items: baseline;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: calc(100% - 4rem);
    z-index: 999;
    bottom: 0px;
    margin: 2rem;
    border-radius: 1rem;
  }

  .cookie-content {
    margin-right: 1rem;
    margin-bottom: 1rem;
    ${media.md`
      flex: 1;
      margin: 0;
    `}
  }
  .cookie-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cookie-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${color.TEAL};
    padding: 0 16px;
    height: 28px;
    border-radius: 28px;
    background-color: ${color.CREME};
    text-decoration: none;
    font-weight: bold;
    font-size: 0.75rem;
    transition: all 0.5s ease;
    border: 0;
    &:last-child {
      margin-left: 0.5rem;
    }
    &:hover {
      background-color: #fff;
    }
  }
`

const Wrapper = styled.div`
  transition: background-color 1s ease-in-out;
`

const Layout = ({ children, bgColor = 'CREME' }) => {
  const [bg, setBg] = React.useState(bgColor)

  const handleBgColor = ({ detail }) => {
    // console.log('entering', detail.target.dataset.color);
    setBg(detail.target.dataset.color);
  }

  React.useEffect(() => {
    document.addEventListener('sal:in', handleBgColor);
    return () => document.removeEventListener('sal:in', handleBgColor);
  }, []);

  return (
    <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
      <Wrapper style={{ backgroundColor: `${color[bg]}` }}>
        {children}
        <CookieConsent
          disableStyles
          containerClasses="cookie-consent"
          contentClasses="cookie-content"
          buttonWrapperClasses="cookie-button-wrapper"
          buttonClasses="cookie-button"
          declineButtonClasses="cookie-button decline"
          declineButtonStyle
          location="bottom"
          enableDeclineButton
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gdpr-google-analytics"
        >
          We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners.
        </CookieConsent>
        <GlobalStyle />
      </Wrapper>
    </StyleSheetManager>
  )
}

export default Layout

// this is for our layouts that center content our normal width
// like FAQ pages and  helpful-parenting-posts pages
export const CenterPage = styled(({ children, ...restProps }) => {
  return ( 
    <div {...restProps}>{children}</div>
  );
})`
  width: calc(100vw - 32px);
  max-width: 48rem;
  margin: 24px auto;
  ${media.md`
    margin: 48px auto;
  `}
`;